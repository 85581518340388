












































































import { Component, Vue, Prop } from 'vue-property-decorator';
import { TimelineItem } from '@/types/cms/components';

/**
 * HistoryTimelineFormDialog component
 * 
 * @author Kevin Danne <danne@skiba-procomputer.de>
 */
@Component
export default class HistoryTimelineFormDialog extends Vue {
    // Prop of type boolean that defined whether the dialog should be shown or not
    @Prop(Boolean) readonly showDialog: boolean | undefined;

    // Prop that holds the timeline item data
    @Prop({type: Object as () => TimelineItem}) readonly editedTimelineItem: TimelineItem | undefined;

    // Prop that holds the id of the edited timeline
    @Prop(Number) readonly editedTimelineIndex: number | undefined;

    // An crud string that defines the mode of the dialog
    @Prop(String) readonly dialogMode: string | undefined;

    // Boolean that defines wheter the form data is valid or not
    private formValid = false;

    /**
     * @returns the title for the form
     * 
     * @author Kevin Danne <danne@skiba-procomputer.de>
     */
    get formTitle(): string {
        return (this.dialogMode) ? this.$t('form.titles.' + this.dialogMode + 'Timeline').toString() : '';
    }

    /**
     * Resets the form validation
     * 
     * @author Kevin Danne <danne@skiba-procomputer.de>
     */
    private resetFormValidation() {
        if (this.$refs.form) {
            (this.$refs.form as Vue & { resetValidation: () => void }).resetValidation();
        }
    }

    /**
     * Saves/Updates or deletes the timeline item
     * 
     * @author Kevin Danne <danne@skiba-procomputer.de>
     */
    private saveTimelineItem() {
        if (this.editedTimelineItem) {
            this.resetFormValidation();
            this.$emit('timelineItemSaved', this.dialogMode, this.editedTimelineIndex, this.editedTimelineItem);
        }
    }

    /**
     * Closes the dialog
     * 
     * @author Kevin Danne <danne@skiba-procomputer.de>
     */
    private closeDialog() {
        this.resetFormValidation();
        this.$emit('dialogClosed');
    }
}
